import React from "react"
import { graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Helmet from "react-helmet"

const useStyles = makeStyles(theme => ({
  container: {
    // marginTop: "20px",
    // marginBottom: "30px",
    paddingBottom: "30px",
    backgroundColor: "#fff",
  },
  title: {
    marginTop: 0,
    paddingTop: "1rem",
  },
  content: {
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      color: theme.palette.primary.dark,
    },
    "& ul": {
      listStyleType: "circle",
    },
    "& ul p": {
      margin: 0,
      padding: 0,
    },
  },
}))

const TermsServicePage = ({ data, location }) => {
  const classes = useStyles()
  const { markdownRemark, twitter } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark

  return (
    <Layout dark>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          Simpleen Terms of Service
        </Typography>

        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Container>
      <Seo
        general={{
          title: "Terms of Service for simpleen.io",
          description:
            "Terms of Service including prohibited uses and a lot of legal stuff for simpleen.io",
          language: "en",
          path: location.pathname,
          image: twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const privacyQuery = graphql`
  query SimpleenTermsQuery {
    markdownRemark(frontmatter: { slug: { eq: "/terms-service" } }) {
      html
      frontmatter {
        slug
        title
      }
    }
    twitter: file(relativePath: { eq: "seo/SEOImageFormat.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
  }
`

export default TermsServicePage
